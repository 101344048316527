<template>
  <f7-page noNavbar name="login">

      <f7-list-input
        label="Password"
        type="password"
        placeholder="Password"
      ></f7-list-input>

  </f7-page>
</template>

<script>
  export default {
    mounted() {
      document.title = 'Login - Blacktown Hospital'
    },
    data() {
      return {

      }
    }
  }
</script>