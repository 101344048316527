
import HomePage from '../pages/home.vue';
import IntroPage from '../pages/intro.vue';
import ECGAndVitalSignsPage from '../pages/ecg-and-vital-signs.vue';
import StElevationYes from '../pages/st-elevation-yes.vue';
import StElevationNo from '../pages/st-elevation-no.vue';
import StElevationECGCriteria from '../pages/st-elevation-ecg-criteria.vue';
import LBBBCriteria from '../pages/lbbb-criteria.vue';
import StratifyAcsRiskHigh from '../pages/stratify-acs-risk-high.vue';
import StratifyAcsRiskIntermediate from '../pages/stratify-acs-risk-intermediate.vue';
import StratifyAcsRiskLow from '../pages/stratify-acs-risk-low.vue';
import ManagementRiskHigh from '../pages/management-risk-high.vue';
import ManagementRiskIntermediate from '../pages/management-risk-intermediate.vue';
import ManagementRiskLow from '../pages/management-risk-low.vue';
import StemiManagement from '../pages/stemi-management.vue';
import ChestpainPathway from '../pages/chestpain-pathway.vue';
import TestingGuideline from '../pages/testing-guideline.vue';
import Troponin from '../pages/troponin.vue';
import References from '../pages/references.vue';
import Thrombolysis from '../pages/thrombolysis.vue';
import Anticoagulation from '../pages/anticoagulation.vue';
import HSTropCalculator from '../pages/hs-trop-calculator.vue';
import ConsiderAorticAndPulmonaryPage from '../pages/consider-aortic-and-pulmonary.vue';
import AlternativeCausesOfTroponinElevation from '../pages/alternative-causes-of-troponin-elevation.vue';
import FormPage from '../pages/form.vue';
import Login from '../pages/login.vue';
import Feedback from '../pages/feedback.vue';


import DynamicRoutePage from '../pages/dynamic-route.vue';
import RequestAndLoad from '../pages/request-and-load.vue';
import NotFoundPage from '../pages/404.vue';

var routes = [
  {
    path: '/',
    component: HomePage,
    options: {
      props: {
        title: 'Home - Blacktown Hospital PACSA'
      }
    }
  },
  {
    path: '/intro/',
    component: IntroPage,
  },
  {
    path: '/ecg-and-vital-signs/',
    component: ECGAndVitalSignsPage,
  },
  {
    path: '/st-elevation-yes/',
    component: StElevationYes,
  },
  {
    path: '/st-elevation-no/',
    component: StElevationNo,
  },
  {
    path: '/st-elevation-ecg-criteria/',
    component: StElevationECGCriteria,
  },
  {
    path: '/lbbb-criteria/',
    component: LBBBCriteria,
  },
  {
    path: '/stratify-acs-risk-high/',
    component: StratifyAcsRiskHigh,
  },
  {
    path: '/stratify-acs-risk-intermediate/',
    component: StratifyAcsRiskIntermediate,
  },
  {
    path: '/stratify-acs-risk-low/',
    component: StratifyAcsRiskLow,
  },
  {
    path: '/management-risk-high/',
    component: ManagementRiskHigh,
  },
  {
    path: '/management-risk-intermediate/',
    component: ManagementRiskIntermediate,
  },
  {
    path: '/management-risk-low/',
    component: ManagementRiskLow,
  },
  {
    path: '/stemi-management/',
    component: StemiManagement,
  },
  {
    path: '/edacs/',
    component: ChestpainPathway,
  },
  {
    path: '/testing-guideline/',
    component: TestingGuideline,
  },
  {
    path: '/thrombolysis/',
    component: Thrombolysis,
  },
  {
    path: '/references/',
    component: References,
  },
  {
    path: '/consider-aortic-and-pulmonary/',
    component: ConsiderAorticAndPulmonaryPage,
  },
  {
    path: '/troponin/',
    component: Troponin,
  },
  {
    path: '/anticoagulation/',
    component: Anticoagulation,
  },
  {
    path: '/alternative-causes-of-troponin-elevation/',
    component: AlternativeCausesOfTroponinElevation,
  },
  {
    path: '/hs-trop-calculator/',
    component: HSTropCalculator,
  },
  {
    path: '/login/',
    component: Login,
  },
  {
    path: '/feedback/',
    component: Feedback,
  },
  {
    path: '/form/',
    component: FormPage,
  },


  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  {
    path: '/request-and-load/user/:userId/',
    async: function (routeTo, routeFrom, resolve, reject) {
      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = routeTo.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Vladimir',
          lastName: 'Kharlampidi',
          about: 'Hello, i am creator of Framework7! Hope you like it!',
          links: [
            {
              title: 'Framework7 Website',
              url: 'http://framework7.io',
            },
            {
              title: 'Framework7 Forum',
              url: 'http://forum.framework7.io',
            },
          ]
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            context: {
              user: user,
            }
          }
        );
      }, 1000);
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
