<template>
  <f7-page noNavbar name="stratify-acs-risk-intermediate">

    <f7-block strong class="intermediate-risk-block mb-0 mt-0">
      <h3 class="mb-1 color-black">Intermediate Risk</h3>
      <div><em>If you select <strong>ALL</strong> of the following you will be taken straight to <strong>INTERMEDIATE RISK</strong> recommended further management</em></div>
    </f7-block>

    <f7-list media-list class="mb-0 mt-0">
      <html-list-item
        checkbox
        name="acs_risk"
        value="acsi1"
        :checked="getAnswer('acsi1')===true"
        @change="setAnswerCheckbox"
        title="Ongoing <strong>atypical</strong> chest pain despite initial treatment. Including Aspirin, Oxygen, GTN, Morphine and if appropriate NSAID, Proton Pump inhibitor."
      ></html-list-item>
      <html-list-item
        checkbox
        name="acs_risk"
        value="acsi1a"
        :checked="getAnswer('acsi1a')===true"
        @change="setAnswerCheckbox"
        title="Other causes of ongoing chest pain excluded, including:"
      ></html-list-item>
      <html-list-item
        name="acs_risk"
        value="acsi1b"
        :title="this.otherCausesIncluding"
      ></html-list-item>
    </f7-list>

    <f7-block class="text-center block-or">
      <div><em>AND</em></div>
    </f7-block>

    <f7-block strong class="mb-0 mt-0">
      <div class="block-text"><strong>ECG - </strong> no high risk changes but <strong>NEW</strong> changes in <strong>2</strong> contigious leads of:</div>
    </f7-block>

    <f7-list media-list class="mb-0 mt-0">
      <html-list-item
        checkbox
        name="acs_risk"
        value="acsi2"
        :checked="getAnswer('acsi2')===true"
        @change="setAnswerCheckbox"
        title="Moderate T wave inversion >1mm <3mm"
      ></html-list-item>
    </f7-list>

    <f7-block class="text-center block-or">
      <div><em>OR</em></div>
    </f7-block>

    <f7-list media-list class="mb-0 mt-0">
      <html-list-item
        checkbox
        name="acs_risk"
        value="acsi3"
        :checked="getAnswer('acsi3')===true"
        @change="setAnswerCheckbox"
        title="<strong>HS troponin I</strong> ≤ 50ng/L"
      ></html-list-item>
    </f7-list>

    <f7-toolbar bottom>
      <div></div>
      <f7-link :class="{disabled: !allCheckedButNone() }" :href="nextPageSome">Next<f7-icon size="20px" ios="f7:chevron_right"></f7-icon></f7-link>
    </f7-toolbar>
    
  </f7-page>
</template>

<script>
  import HtmlListItem from './html-list-item.vue';
  import helpers from './helpers.vue';
  export default {
    extends: helpers,
    mounted() {
      document.title = 'Intermediate Risk - Stratify - Blacktown Hospital PACSA'
    },
    data() {
      return {
        section: 'acs_risk_intermediate',
        nextPageSome: '/management-risk-intermediate/',
        // nextPageNone: '/stratify-acs-risk-low/',
        // questions: [
        //   { id: 'acsi1', name: 'acs_risk', title: 'Ongoing atypical chest pain despite initial treatment in ED' },
        //   { id: 'acs_1', name: 'acs_risk', title: 'OR', isDivider: true },
        //   { id: 'acs_2', name: 'acs_risk', title: 'ECG - no high risk changes but NEW changes in 2 contigious leads of:', isDivider: true },
        //   { id: 'acsi2', name: 'acs_risk', title: 'Moderate T wave inversion >1mm <3mm' },
        //   { id: 'acs_3', name: 'acs_risk', title: 'OR', isDivider: true },
        //   { id: 'acsi3', name: 'acs_risk', title: 'HS troponin I 17–50ng/L' },
        // ],
        otherCausesIncluding: "<ol type='a' class='question-list'>"+
        "<li>Aortic dissection</li>"+
        "<li>Pericarditis</li>"+
        "<li>Pulmonary embolism</li>"+
        "<li>Trauma</li>"+
        "<li style='margin-bottom:0;'>Gastrointestinal Musculoskeletal</li>"+
        "</ol>",
      }
    },
    components: {
      HtmlListItem,
    },
    methods: {
      allCheckedButNone: function() {
        var idList = [
          'acsi1',
          'acsi1a',
        ];
        var idList2 = [
          'acsi2',
          'acsi3',
        ];
        return this.everyAnswers(idList) && this.someAnswers(idList2);
      },
    },
  }
</script>