<template>
  <f7-page noNavbar name="troponin">

    <f7-block class="text-center">
      <p class="mb-0 med-page-title"><strong>Feedback</strong></p>
    </f7-block>

    <f7-block class="text-center">
        <p>Please provide feedback. It will be reviewed to improve the app.</p>
        <p>If you have clinical question about a current patient <strong>DO NOT</strong> use this feedback form. Ask your supervisor.</p>
    </f7-block>

    <f7-list>
        <f7-list-input
            label="Please select your feedback category below"
            type="select"
            defaultValue=""
            placeholder="Make a selection"
            :value="category"
            @input="category = $event.target.value"
        >
            <option value="">-- Make a selection --</option>
            <option value="Questions">Questions</option>
            <option value="Suggestions">Suggestions</option>
            <option value="Compliment">Compliment</option>
            <option value="Other">Other</option>
        </f7-list-input>

        <f7-list-input
            label="Insert your feedback here"
            type="textarea"
            resizable
            placeholder="..."
            :value="feedback"
            @input="feedback = $event.target.value"
        >
    </f7-list>

    <f7-block class="text-center">
        <f7-button fill @click="submitEmail()">Submit</f7-button>
    </f7-block>
    

  </f7-page>
</template>

<script>
  export default {
    mounted() {
      document.title = 'Feedback - Blacktown Hospital PACSA'
    },
    data() {
      return {
        category: '',
        feedback: '',
      }
    },
    methods: {
      submitEmail: function() {
        // console.log('emailbutton');
        // console.log(this.category);
        // console.log(this.feedback);
        if (this.category != '' && this.feedback != '') {
        // ()=>{
            var emailBody = 'Feedback category:%0D%0A' + this.category + '%0D%0A%0D%0AFeedback:%0D%0A' + this.feedback;
            this.category = '';
            this.feedback = '';
            window.location.href = "mailto:bthpacsa@gmail.com?subject=Blacktown PACSA Feedback&body=" + emailBody
            return
        // }
        } else {
            alert('Please complete all fields.');
        }
      },
    },
  }
</script>