<template>
<f7-app :params="f7params">
  <!-- Status bar overlay for fullscreen mode-->

  <div id="app-login" v-show="!correctPassword" style="position:fixed;top:0;left:0;width:100%;height:100%;background-color: #efeff4;">
    <f7-page no-toolbar no-navbar no-swipeback login-screen>
      <div>
      <f7-login-screen-title>
        <div style="padding: 0 20px;">
          <img src="static/images/logo.jpg" class="logo" border="0" style="max-width: 100%;display:block;margin:0 auto;">
        </div>
      </f7-login-screen-title>
      <f7-list>
        <f7-list-input
          input-id="mainPassword"
          label=""
          type="password"
          placeholder="Enter password to continue..."
          :value="inputValue"
          @input="inputValue = $event.target.value"
          @keyup.enter.native="signIn()"
        ></f7-list-input>
      </f7-list>
      <f7-list v-show="inputError">
        <f7-block-footer><span style="color:red;">Password is incorrect.</span></f7-block-footer>
      </f7-list>
      <f7-list>
        <f7-block>
          <div><f7-button large fill @click="signIn()">Continue</f7-button></div>
          <!-- <f7-block-footer>Some text about login information.<br>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</f7-block-footer> -->
        </f7-block>
      </f7-list>
      </div>
    </f7-page>
  </div>

  <f7-statusbar v-show="correctPassword"></f7-statusbar>

  <!-- Right panel with reveal effect-->
  <f7-panel right cover v-show="correctPassword">
    <f7-page>
      <f7-navbar class="panel-nav-title" title="Main Navigation"></f7-navbar>
      <f7-list links-list class="nav-content">
        <f7-list-button link="#" @click="returnHome()" class="item-link" title="Home" panel-close></f7-list-button>
        <f7-list-button link="/stratify-acs-risk-high/" class="item-link" title="Stratify Risk" panel-close></f7-list-button>
        <f7-list-button link="/management-risk-high/" class="item-link risk-link red-link" title="High Risk" panel-close></f7-list-button>
        <f7-list-button link="/management-risk-intermediate/" class="item-link risk-link yellow-link" title="Intermediate Risk" panel-close></f7-list-button>
        <f7-list-button link="/management-risk-low/" class="item-link risk-link green-link" title="Low Risk" panel-close></f7-list-button>
        <f7-list-button link="/stemi-management/" class="item-link" title="STEMI Management" panel-close></f7-list-button>
        <f7-list-button link="/troponin/" class="item-link" title="High sensitivity Troponin I" panel-close></f7-list-button>
        <f7-list-button link="/hs-trop-calculator/" class="item-link" title="HS Trop I calculator BTMD Siemens Vista HS assay" panel-close></f7-list-button>
        <f7-list-button link="/anticoagulation/" class="item-link" title="Anticoagulation" panel-close></f7-list-button>
        <f7-list-button link="/edacs/" class="item-link" title="EDACS" panel-close></f7-list-button>
        <f7-list-button link="/testing-guideline/" class="item-link" title="Non Invasive Testing Guideline" panel-close></f7-list-button>
        <f7-list-button link="/references/" class="item-link" title="References" panel-close></f7-list-button>
        <f7-list-button link="/feedback/" class="item-link" title="Feedback" panel-close></f7-list-button>
      </f7-list>
    </f7-page>
  </f7-panel>

  <f7-appbar v-show="correctPassword">
    <div class="left">
      <a class="link back" id="appbar-back">
        <i class="icon icon-back"></i>
        <span class="if-not-md">Back</span>
      </a>
    </div>
    <div class="center">
      <img src="static/images/logo.jpg" class="logo" border="0" style="max-height: 40px;display:block;">
    </div>
    <div class="right">
      <a href="#" class="button button-small panel-toggle display-flex" data-panel="right">
        <i class="f7-icons">bars</i>
      </a>
    </div>
  </f7-appbar>
  
  <!-- Your main view, should have "view-main" class -->
  <f7-view main :push-state="true" class="safe-areas" url="/" v-show="correctPassword"></f7-view>

</f7-app>
</template>
<script>

  import routes from '../js/routes.js';

  export default {
    data() {
      return {
        // Framework7 Parameters
        f7params: {
          name: 'Blacktown Hospital - Chest Pain Pathway', // App name
          theme: 'ios', // Automatic theme detection
          // App root data
          on: {
            routeChange: function (page) {
              if (page.path == '/') {
                document.getElementById("appbar-back").classList.remove("active");
              } else {
                document.getElementById("appbar-back").classList.add("active");
              }
            },
            pageBeforeIn: function (e, page) {

            },
            pageInit: function (page) {
              gtag('config', 'UA-163586959-1', {'page_path': page.route.path});
            },
          },
          // App routes
          routes: routes,

          // Register service worker
          serviceWorker: {
            path: '/service-worker.js',
          },
        },
        inputValue: '',
        correctPassword: this.getCookie('btpass') == 'blacktown' ? true : false,
        inputError: false
      }
    },
    computed: {
      validPassword: function() {
        return false;
      }
    },
    methods: {
      returnHome: function() {
        this.$f7.views.main.router.back('/', { force:true });
      },
      signIn() {
        if (this.inputValue == 'blacktown') {
          // if password correct set password in data and add a cookie
          var d = new Date();
          var exdays = 90;
          d.setTime(d.getTime() + (exdays*24*60*60*1000));
          var expires = "expires="+ d.toUTCString();
          document.cookie = "btpass="+this.inputValue+"; "+expires+"; path=/"
          // reset
          this.correctPassword = true;
          this.inputError = false;
          this.inputValue = '';
        } else {
          // if password incorrect show error message
          this.correctPassword = false;
          this.inputError = true;
          this.inputValue = '';
        }
      },
      getCookie(name) {
        var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
      }
    },
    mounted() {
      this.$f7ready((f7) => {

        // Call F7 APIs here
      });
    }
  }
</script>